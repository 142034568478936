import messages from './messages';

export const TRAINING_CONTENT = {
  title: messages.title,
  descriptions: [
    messages.description_01,
    messages.description_02,
    messages.description_03,
    messages.description_04,
  ],
  timeframe: {
    amount: '2-3',
    unit: messages.timeUnit,
  }
};

export const TRAINING_MODULES = [
  messages.module_01,
  messages.module_02,
  messages.module_03,
  messages.module_04,
  messages.module_05,
  messages.module_06,
  messages.module_07,
  messages.module_08,
  messages.module_09,
  messages.module_10,
  messages.module_11,
  messages.module_12,
  messages.module_13,
  messages.module_14,
  messages.module_15,
  messages.module_16,
]
