const scope = 'components.TrainingPageContent.DDDAppliedTraining';

export default {
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Domain-Driven Design Applied',
  },
  timeUnit: {
    id: `${scope}.timeUnit`,
    defaultMessage: 'dni',
  },
  description_01: {
    id: `${scope}.description_01`,
    defaultMessage: 'Dowiedz się jak poradzić sobie ze złożonością w Twojej domenie biznesowej.',
  },
  description_02: {
    id: `${scope}.description_02`,
    defaultMessage: 'Poznaj strategiczne wzorce, które pomogą znaleźć najbardziej wartościowe części Twojego systemu, oraz zbudować niezależne zespoły pracujące nad nimi.\n',
  },
  description_03: {
    id: `${scope}.description_03`,
    defaultMessage: 'Poznaj taktyczne wzorce, które ułatwią komunikację programistów i testerów z biznesem.',
  },
  description_04: {
    id: `${scope}.description_04`,
    defaultMessage: 'Zastosuj zdobytą wiedzę od razu, bezpośrednio w kodzie.',
  },
  module_01: {
    id: `${scope}.module_01`,
    defaultMessage: 'Strategic Module I - Powiązanie z Modelem Biznesowym',
  },
  module_02: {
    id: `${scope}.module_02`,
    defaultMessage: 'Strategic Module II - Domains, Subdomains i Bounded Contexts',
  },
  module_03: {
    id: `${scope}.module_03`,
    defaultMessage: 'Strategic Module III - Core Domains i Strategic Design',
  },
  module_04: {
    id: `${scope}.module_04`,
    defaultMessage: 'Strategic Module IV - Zespoły i relacje między Bounded Context\'ami',
  },
  module_05: {
    id: `${scope}.module_05`,
    defaultMessage: 'Architecture Module I - Architektury wspierające DDD',
  },
  module_06: {
    id: `${scope}.module_06`,
    defaultMessage: 'Architecture Module II - Integrowanie Bounded Context\'ów',
  },
  module_07: {
    id: `${scope}.module_07`,
    defaultMessage: 'Architecture Module III - Command-Query Responsibility Segregation',
  },
  module_08: {
    id: `${scope}.module_08`,
    defaultMessage: 'Architecture Module IV - DDD w Systemach Legacy',
  },
  module_09: {
    id: `${scope}.module_09`,
    defaultMessage: 'Tactical Module I - Wzorce taktyczne',
  },
  module_10: {
    id: `${scope}.module_10`,
    defaultMessage: 'Tactical Module II - Persystencja Agregatów',
  },
  module_11: {
    id: `${scope}.module_11`,
    defaultMessage: 'Tactical Module III - Event Sourcing',
  },
  module_12: {
    id: `${scope}.module_12`,
    defaultMessage: 'Tactical Module IV - Sagi i Procesy Biznesowe',
  },
  module_13: {
    id: `${scope}.module_13`,
    defaultMessage: 'Workshop Module I - Big Picture Event Storming',
  },
  module_14: {
    id: `${scope}.module_14`,
    defaultMessage: 'Workshop Module II - Process Modeling Event Storming',
  },
  module_15: {
    id: `${scope}.module_15`,
    defaultMessage: 'Workshop Module III - Software Design Event Storming',
  },
  module_16: {
    id: `${scope}.module_16`,
    defaultMessage: 'Workshop Module IV - Architectural Kata',
  },
}
