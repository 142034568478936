import React from 'react';

import TrainingPageContent from '../TrainingPageContent';
import {TRAINING_PAGE_LINK, TRAINING_PAGE_TITLE} from '../../../constants';
import {TRAINING_CONTENT, TRAINING_MODULES} from './config';

const DDDAppliedTraining = () => (
  <TrainingPageContent
    title={TRAINING_CONTENT.title}
    descriptions={TRAINING_CONTENT.descriptions}
    modules={TRAINING_MODULES}
    timeframe={TRAINING_CONTENT.timeframe.amount}
    nextTraining={{
      name: TRAINING_PAGE_TITLE.CQRS_AXON,
      link: TRAINING_PAGE_LINK.CQRS_AXON,
    }}
  />
);

export default DDDAppliedTraining;
