import React from 'react';
import {Helmet} from 'react-helmet';
import {string} from 'prop-types';
import {useIntl} from 'gatsby-plugin-intl';

import Layout from '../../components/Layout';
import DDDAppliedTraining from '../../components/TrainingPageContent/DDDAppliedTraining';
import {TRAINING_PAGE_TITLE} from '../../constants';
import {TRAINING_CONTENT} from '../../components/TrainingPageContent/DDDAppliedTraining/config';
import messages from '../../components/TrainingPageContent/messages'

const DomainDrivenDesignTrainingPage = ({path}) => {
  const {formatMessage} = useIntl();
  const {descriptions} = TRAINING_CONTENT;
  const pageTitle = `${formatMessage(messages.training)} ${TRAINING_PAGE_TITLE.DDD_APPLIED}`
  const description = `${formatMessage(descriptions[0])} ${formatMessage(descriptions[1])}`;

  return (
    <Layout path={path}>
      <Helmet>
        <title>
          {pageTitle}
        </title>
        <meta name='description' content={description}/>
      </Helmet>
      <DDDAppliedTraining/>
    </Layout>
  );
}


DomainDrivenDesignTrainingPage.propTypes = {
  path: string.isRequired,
}

export default DomainDrivenDesignTrainingPage;
